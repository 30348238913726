import React, { ReactNode } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Image } from '@/components/_root';
import { motion } from 'framer-motion';

interface Props {
  children: ReactNode;
}

interface ImageProps {
  bookImageUrl: IGatsbyImageData | any;
}

export const FlexWrapper = ({ children }: Props): JSX.Element => (
  <div className="relative flex flex-col lg:flex-row">{children}</div>
);
export const ImageSection = ({ bookImageUrl }: ImageProps): JSX.Element => (
  <div className="text-center lg:w-5/12 relative">
    <motion.div
      className="relative"
      whileHover={{
        transition: { duration: 0.6, type: 'spring' },
        top: '6rem',
      }}
      whileTap={{ top: '6rem' }}
    >
      <Image
        src={bookImageUrl}
        alt=""
        className={`w-72 md:w-96 lg:w-full cursor-pointer transform rotate-6 top-4  lg:top-20`}
        objectFit="contain"
      />
    </motion.div>
  </div>
);
