import React, { ReactNode } from 'react';
import { IconAward } from '@/components/_icons';
import { Image } from '@/components/_root';
import { StaticImage } from 'gatsby-plugin-image';

/**
 * [CaseStudiesSection description]
 * @type {[type]}
 */
export const CaseStudiesSection = ({
  backgroundBanner,
  children,
}: {
  backgroundBanner: string;
  children: ReactNode;
}): JSX.Element => {
  return (
    <div className="relative">
      <StaticImage
        src="../../../assets/img/blog-hub-banner.png"
        alt=""
        className="w-full z-10 h-full opacity-60"
        style={{ position: 'absolute' }}
        placeholder="blurred"
      />
      {backgroundBanner && (
        <Image
          src={backgroundBanner}
          alt=""
          className="w-full z-0 h-full"
          style={{ position: 'absolute' }}
        />
      )}
      <div className={`relative z-40 text-white`}>{children}</div>
    </div>
  );
};

CaseStudiesSection.SectionLogo = ({ logo }: { logo: string }): JSX.Element => {
  return <h2 className={`text-white mb-6 whitespace-nowrap overflow-ellipsis`}>{logo}</h2>;
};

/**
 * [CaseStudiesContent description]
 * @param {[type]} children [description]
 */
export const CaseStudiesContent = ({ children }: { children: ReactNode }): JSX.Element => (
  <div className={`grid lg:grid-cols-2 gap-4 md:grid-cols-1`}>
    <>{children}</>
  </div>
);

/**
 * [CaseStudiesActions description]
 * @param {[type]} children [description]
 */
export const CaseStudiesActions = ({ children }: { children: ReactNode }): JSX.Element => (
  <div className={`flex flex-col sm:flex-row gap-5 sm:gap-9 sm:items-center`}>{children}</div>
);

CaseStudiesActions.Awards = ({
  count,
  awardIcon,
}: {
  count: number;
  awardIcon: any;
}): JSX.Element => (
  <div className={`flex flex-row items-center`}>
    <h5 className={`whitespace-nowrap`}>{count} awards</h5>
    <div className={`flex flex-row ml-3`}>
      {count > 5 ? (
        <div className="flex">
          <div className={`mx-1`}>{awardIcon ? <Image src={awardIcon} /> : <IconAward />}</div>
          <div className={`mx-1`}>{awardIcon ? <Image src={awardIcon} /> : <IconAward />}</div>
          <span className="text-xl leading-xs px-1">...</span>
          <div className={`mx-1`}>{awardIcon ? <Image src={awardIcon} /> : <IconAward />}</div>
        </div>
      ) : (
        Array.from({ length: count }, (_, i) => i).map((_, index) => (
          <div className={`mx-1`} key={index}>
            {awardIcon ? <Image src={awardIcon} /> : <IconAward />}
          </div>
        ))
      )}
    </div>
  </div>
);

CaseStudiesSection.ProgressBar = ({
  elapsedTime,
  totalTime,
}: {
  elapsedTime: number;
  totalTime: number;
}): JSX.Element => (
  <div style={{ position: 'absolute', bottom: 2, right: 0, left: 0 }}>
    <div className="relative pt-1">
      <div className="overflow-hidden h-1 text-xs flex">
        <div
          style={{
            width: `${(elapsedTime / totalTime) * 100}%`,
            transition: `0.2s`,
            backgroundImage: 'linear-gradient(to right, #9542F6, #EA36A8)',
          }}
          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
        ></div>
      </div>
    </div>
  </div>
);

/**
 * [CaseStudyListHeader description]
 * @type {[type]}
 */
export const CaseStudyListHeader = ({ children }: { children: ReactNode }): JSX.Element => (
  <div className={`flex flex-row items-center justify-between mt-20 mb-4`}>{children}</div>
);

export const CaseStudiesItem = ({
  children,
  isHover,
  onMouseOver,
  onMouseLeave,
  onClick,
  height,
  className = '',
}: any): JSX.Element => (
  <div
    onMouseOver={onMouseOver}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
    className={`rounded cursor-pointer overflow-hidden transition-all duration-300 ease-in-out ${!isHover ? `pt-12` : ``
      } ${className}`}
    style={{ height }}
  >
    {children}
  </div>
);

CaseStudiesItem.Title = ({ title, isHover }: { title: string; isHover: boolean }): JSX.Element => (
  <div
    className={`flex flex-row justify-between absolute text-white w-full bottom-0 left-0 px-4 ${isHover
        ? `py-5 bg-black bg-opacity-50`
        : `py-3 bg-gradient-to-b from-transparent to-black bg-opacity-50`
      } transition-all duration-300 ease-in-out items-center rounded-bl rounded-br`}
  >
    <h4 className={`truncate`}>{title}</h4>
    <h5 className={`text-xs whitespace-nowrap px-1 ${isHover ? `visible` : `invisible`}`}>
      Preview {`>`}
    </h5>
  </div>
);
