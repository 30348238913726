/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { Container, HeaderText, PageSection } from '@/components/_root';
import {
  HeadingWrapper,
  ServicesWrapper,
  ServicesItem,
  TextContent,
  // DriversServicesWrapper,
  // FacilitatorsServicesWrapper,
  ServiceDivider,
  CategoryServicesWrapper,
} from './styled';
import SectionTitle from '@/components/section-title';
import BlockContent from '../block-content';
import * as styles from './styled/style.module.css';

interface IService {
  title: string;
  description: string;
  link: string;
}

interface Props {
  title: { outlined: string; solid: string; isOutlinedFirst?: boolean };
  description: string;
  services: IService[];
}

interface CategoryProps {
  title: { outlined?: string; solid: string; isOutlinedFirst?: boolean };
  description: string;
  services_category: { title?: string; services: IService[] }[];
}

const Services = ({ title, description, services }: Props): JSX.Element => {
  return (
    <>
      <PageSection mode="dark" paddingAmount="small">
        <Container>
          <HeadingWrapper>
            <SectionTitle title={title} mode="dark" />
          </HeadingWrapper>
          <BlockContent blocks={description} />
          <ServicesWrapper>
            <Services.Items services={services} />
          </ServicesWrapper>
        </Container>
      </PageSection>
    </>
  );
};

Services.Category = ({ title, description, services_category }: CategoryProps): JSX.Element => {
  return (
    <>
      <PageSection mode="dark" paddingAmount="small">
        <Container>
          <HeadingWrapper>
            <SectionTitle title={title} mode="dark" />
          </HeadingWrapper>
          <TextContent>{description}</TextContent>
          <ServiceDivider>
            {/* <div className="w-[68%]">
              <HeaderText.Outline mode="dark" size="h3" className="pb-4 md:pb-8">
                The Drivers
              </HeaderText.Outline>
              <DriversServicesWrapper>
                <Services.Items services={driversServices} />
              </DriversServicesWrapper>
            </div>
            <div className="w-[32%] lg:pl-12">
              <HeaderText.Outline mode="dark" size="h3" className="pt-8 lg:pt-0 pb-4 md:pb-8">
                The Facilitators
              </HeaderText.Outline>
              <FacilitatorsServicesWrapper>
                <Services.Items services={facilitatorsServices} />
              </FacilitatorsServicesWrapper>
            </div> */}
            {services_category.map((category, index) => (
              <div key={index} className={styles.categoryDivider}>
                <HeaderText.Outline mode="dark" size="h3" className="pb-4 md:pb-8">
                  {category?.title}
                </HeaderText.Outline>
                <CategoryServicesWrapper
                  className={category?.services.length > 2 ? 'lg:grid-cols-5' : 'lg:grid-cols-2'}
                >
                  <Services.Items services={category?.services} />
                </CategoryServicesWrapper>
              </div>
            ))}
          </ServiceDivider>
        </Container>
      </PageSection>
    </>
  );
};

Services.Items = ({ services }: { services: IService[] }): JSX.Element => {
  const [hoverIndex, setHoverIndex] = useState<number>(-1);
  const lastIndex = services?.length - 1;

  return (
    <>
      {services?.map((service, index) => (
        <div key={index}>
          <ServicesItem
            index={index}
            serviceName={service.title}
            serviceDescription={service.description}
            lastIndex={lastIndex}
            hoverIndex={hoverIndex}
            onHover={(i: number): void => setHoverIndex(i)}
            findOutMoreLink={service.link}
            hoverElementFullWidth
          />
        </div>
      ))}
    </>
  );
};

export default Services;
