import React from 'react';
import { PageSection, Swiper, SwiperItem, Image } from '@/components/_root';
import { HeaderTextContainer, OverlayContainer } from './styled';
import SectionTitle from '@/components/section-title';

interface Props {
	title: { solid: string; outlined: string; isOutlinedFirst?: boolean };
	awardLogos: any[];
}

const AwardsSection = ({ title, awardLogos }: Props): JSX.Element => {
	return (
		<OverlayContainer>
			<PageSection paddingAmount='small'>
				<HeaderTextContainer>
					<SectionTitle title={title} mode='dark' />
				</HeaderTextContainer>
				<Swiper isLoop autoPlay showItems={{ sm: 2, md: 3, lg: 4, xl: 5 }}>
					{awardLogos.map((awardLogo, index: number) => (
						<SwiperItem key={index}>
							<div className={`flex h-32 w-auto item-center justify-center`}>
								<Image
									src={awardLogo?.image ?? awardLogo}
									alt=''
									className='object-contain pointer-events-none'
									objectFit={'contain'}
								/>
							</div>
						</SwiperItem>
					))}
				</Swiper>
				<noscript>
					<div className='flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 sm:gap-16 md:gap-20 lg:gap-24 xl:gap-28 px-10'>
						{awardLogos.slice(0, 5).map((awardLogo, index: number) => {
							return (
								<div className='flex h-32 w-auto justify-center items-center'>
									<img
										src={
											awardLogo?.image?.asset?.gatsbyImageData?.images?.fallback
												?.src
										}
										alt={awardLogo.title}
										className='object-contain w-auto h-full'
									/>
								</div>
							);
						})}
					</div>
				</noscript>
			</PageSection>
		</OverlayContainer>
	);
};

export default AwardsSection;
