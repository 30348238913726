export const setBlogFeaturedData = (edges: any[]): any[] => {
  if (!edges?.length) return [];

  return edges.map(({ node }: any) => ({
    love_count: 0,
    like_count: 0,
    clap_count: 0,
    blog: node,
  }));
};

export const hasInvalidBlogData = (blogs: any[]): boolean => {
  if (!blogs || !blogs.length) return false;

  return !!blogs.find(({ blog }) => !blog);
};
