import { ICaseStudy } from '@/utils/types';

const formatCaseStudies = (data: any, isServices: boolean = false): ICaseStudy[] => {
  let formattedData = [];
  if (isServices) {
    formattedData = data?.map((node: any) => {
      return {
        node: {
          id: node.id,
          brandName: node?.brand_name,
          case_study_title: node?.case_study_title,
          lgBannerLink: node?.image,
          thumbnail: node?.thumbnail,
          excerpt: node?.excerpt,
          awardCount: node?.award_count,
          caseStudyUrl: node?.case_study_url,
          slug: node?.slug,
        },
      };
    });
    return formattedData;
  }
  formattedData = data?.map((node: any) => {
    return {
      brandName: node?.our_works?.client,
      title: node?.our_works?.case_study_title,
      //lgBannerLink: node?.our_works?.featuredImage || node?.our_works?.banner,
      //lgBannerLink: node?.our_works?.banner,
      //thumbLink: node?.our_works?.featuredImage || node?.our_works?.thumbnail,
      lgBannerLink: node?.our_works?.featuredImage,
      thumbLink: node?.our_works?.thumbnail,
      summary: node?.our_works?.excerpt,
      awardCount: node?.award_count,
      caseStudyUrl: `/work/${node?.our_works?.slug?.current}/`,
      awardIcon: node?.award_icon,
    };
  });
  return formattedData;
};

export default formatCaseStudies;
