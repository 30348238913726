import React, { useState, useEffect, ReactNode } from 'react';
import { Button } from '@/components/_root';
import { Transition } from '@headlessui/react';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './style.module.css';
interface Props {
  children: ReactNode;
}

interface CategoryProps extends Props {
  className?: string;
}

interface ServicesItemProps {
  index: number;
  lastIndex?: number;
  isOdd?: boolean;
  serviceName: string;
  serviceDescription: string;
  hoverElementFullWidth?: boolean;
  noOdd?: boolean;
  hoverIndex?: number;
  onHover: (i: number) => void;
  findOutMoreLink: string;
  className?: string;
  defaultCursor?: boolean;
}

export const ServicesItem = ({
  index,
  lastIndex,
  serviceName,
  serviceDescription,
  hoverElementFullWidth,
  noOdd = false,
  hoverIndex,
  onHover,
  className,
  findOutMoreLink,
  defaultCursor = false,
}: ServicesItemProps): JSX.Element => {
  const [isOdd, setIsOdd] = useState(false);

  const checkOdd = (i: number): void => {
    i % 2 === 1 ? setIsOdd(true) : setIsOdd(false);
  };

  useEffect(() => {
    if (!noOdd) checkOdd(index);
  }, [index, noOdd]);

  let serviceItemWrapper = `absolute top-full z-10 w-80 -top-2 lg:flex justify-center cursor-pointer ${className}`;
  serviceItemWrapper += ` ${isOdd ? 'right-0 ' : ''}`;
  serviceItemWrapper += ` ${'lg:left-0 '}`;
  const serviceTextBorder =
    'border-gradient-br-grad-purple-pink-dominant border-transparent border-solid border-l-2';
  const serviceTextHover = 'pr-0 relative z-20 bg-transparent border-l-2 border-transparent';
  let serviceTextClass = `${
    !defaultCursor ? `cursor-pointer` : ``
  } h-16 pl-3 lg:pl-2 xl:pl-6 pr-3 mt-4 md:mt-0 flex items-center ${className || ''}`;
  serviceTextClass += ` ${hoverIndex === index ? serviceTextHover : serviceTextBorder}`;

  return (
    <>
      <div
        className="relative "
        onMouseEnter={(): void => onHover(index)}
        onMouseLeave={(): void => onHover(-1)}
      >
        <h4 className={serviceTextClass}>
          <span className="max-w-xs">{serviceName}</span>
        </h4>
        <Transition
          show={hoverIndex === index}
          enter="transition ease-out duration-250"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-100"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          className={`${serviceItemWrapper} ${styles.containerWidth}`}
        >
          <ServicesItemHoverContent
            index={index}
            lastIndex={lastIndex}
            isOdd={isOdd}
            serviceName={serviceName}
            serviceDescription={serviceDescription}
            findOutMoreLink={findOutMoreLink}
          />
        </Transition>
      </div>
    </>
  );
};

export const ServicesItemHoverContent = ({
  index,
  lastIndex,
  isOdd,
  serviceDescription,
  findOutMoreLink,
}: any): JSX.Element => {
  let textWrapper = `absolute inset-y-1 pl-4 lg:pl-6 flex flex-col h-full `;
  textWrapper += `${isOdd ? 'w-full lg:w-10/12  mx-auto ' : 'lg:w-10/12 mx-auto '}`;
  let buttonWrapper = `${'lg:w-11/12 '}`;
  buttonWrapper += `${
    isOdd ? 'w-full pr-4 flex justify-end lg:justify-start' : 'w-11/12 lg:w-full flex '
  }`;
  let descriptionClass = `pt-20 pb-6 pl-2 lg:-ml-1 text-sm `;
  descriptionClass += `${isOdd ? 'pr-4  text-right lg:text-left ' : ''}`;

  return (
    <div className={`absolute -top-20 w-full right-0`}>
      <StaticImage
        placeholder="blurred"
        src="../../../assets/img/services-hover.png"
        alt="hover"
        className="w-full pb-6 h-full rounded-md"
      />
      <div className={textWrapper}>
        <p className={descriptionClass}>{serviceDescription}</p>
        <div className={`${buttonWrapper} mt-auto mb-5`} style={{ letterSpacing: '2%' }}>
          <Button
            shape="round"
            size="small"
            mode="dark"
            type="secondary"
            href={`/services/${findOutMoreLink}/`}
          >
            Find Out More
          </Button>
        </div>
      </div>
    </div>
  );
};

export const ServicesWrapper = ({ children }: Props): JSX.Element => (
  <div className="relative grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 text-white">
    {children}
  </div>
);

export const ServiceDivider = ({ children }: Props): JSX.Element => (
  <div className="flex flex-col lg:flex-row lg:divide-x-2 divide-white divide-dashed">
    {children}
  </div>
);

// export const DriversServicesWrapper = ({ children }: Props): JSX.Element => (
//   <div className="relative grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 text-white">
//     {children}
//   </div>
// );

export const CategoryServicesWrapper = ({ children, className }: CategoryProps): JSX.Element => (
  <div
    className={`relative grid grid-cols-2 md:grid-cols-4 text-white ${
      className ? className : 'grid-flow-col'
    }`}
  >
    {children}
  </div>
);

export const FacilitatorsServicesWrapper = ({ children }: Props): JSX.Element => (
  <div className="relative grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 text-white">
    {children}
  </div>
);

export const HeadingWrapper = ({ children }: Props): JSX.Element => (
  <div className="w-11/12 md:w-8/12 pb-6 md:pb-10 flex flex-col">{children}</div>
);

export const TextContent = ({ children }: Props): JSX.Element => (
  <p className="text-white pb-10 max-w-3xl">{children}</p>
);
