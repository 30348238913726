import React from 'react';
import { Container, PageSection, SwiperItem } from '@/components/_root';
import { FeedbackItem, HeaderWrapper, OverlayContainer } from './styled';
import { IFeedback } from '@/utils/interface';
import CarouselOrGrid from '@/components/carousel-or-grid';
import SectionTitle from '@/components/section-title';

const QUOTE_ICON_SIZE = 30;

interface Props {
	title: { outlined?: string; solid?: string; isOutlinedFirst?: boolean };
	feedbacks: IFeedback[];
	isGradient?: boolean;
}

const ClientsFeedback = ({
	title,
	feedbacks,
	isGradient = false,
}: Props): JSX.Element => {
	return (
		<OverlayContainer isGradient={isGradient}>
			<PageSection mode='light' paddingAmount='small'>
				<Container>
					<HeaderWrapper>
						<SectionTitle title={title} isGradient={isGradient} />
					</HeaderWrapper>
				</Container>

				<CarouselOrGrid>
					{feedbacks?.map((feedback, index) => (
						<SwiperItem key={index}>
							<FeedbackItem
								isGradient={isGradient}
								item={{
									heading: feedback?.quote_primary,
									description: feedback?.quote_secondary,
									brandname: feedback?.client_name,
								}}
								iconSize={QUOTE_ICON_SIZE}
							/>
						</SwiperItem>
					))}
				</CarouselOrGrid>
				<noscript>
					<div className='flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 sm:gap-16 md:gap-20 lg:gap-24 max-w-6xl mx-auto'>
						{feedbacks?.slice(0, 3).map((feedback, index: number) => {
							return (
								<FeedbackItem
									isGradient={isGradient}
									item={{
										heading: feedback?.quote_primary,
										description: feedback?.quote_secondary,
										brandname: feedback?.client_name,
									}}
									iconSize={QUOTE_ICON_SIZE}
								/>
							);
						})}
					</div>
				</noscript>
			</PageSection>
		</OverlayContainer>
	);
};

export default ClientsFeedback;
