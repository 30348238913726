import React from 'react';
import { ClapIcon } from '@/components/_icons';

export const heroReactionsData = [
  {
    id: 1,
    icon: <ClapIcon size="52" />,
    className: 'right-16 -top-16',
    opacity: 'opacity-100',
    animate: {
      scale: [1, 36 / 52],
      translateX: [0, 48],
      translateY: [0, -16],
      opacity: [1, 0],
    },
    transition: {
      duration: 4,
      ease: 'easeInOut',
      times: [0, 0.5],
      repeat: Infinity,
      repeatDelay: 0,
      delay: 0,
    },
  },
  {
    id: 2,
    icon: <ClapIcon size="36" />,
    className: 'right-4 -top-20',
    opacity: 'opacity-70',
    animate: {
      scale: [1, 32 / 36],
      translateX: [0, 0],
      translateY: [0, -48],
      opacity: [1, 0],
    },
    transition: {
      duration: 2,
      ease: 'easeInOut',
      times: [0, 0.5],
      repeat: Infinity,
      repeatDelay: 0,
      delay: 0,
    },
  },
  {
    id: 3,
    icon: <ClapIcon size="32" />,
    className: 'right-4 -top-32',
    opacity: 'opacity-50',
    animate: {
      scale: [1, 24 / 32],
      translateX: [0, 32],
      translateY: [0, -16],
      opacity: [1, 0],
    },
    transition: {
      duration: 3,
      ease: 'easeInOut',
      times: [0, 0.5],
      repeat: Infinity,
      repeatDelay: 0,
      delay: 0,
    },
  },
  {
    id: 4,
    icon: <ClapIcon size="24" />,
    className: '-right-4 -top-36',
    opacity: 'opacity-30',
    animate: {
      scale: [1, 12 / 24],
      translateX: [0, 12],
      translateY: [0, -16],
      opacity: [1, 0],
    },
    transition: {
      duration: 2,
      ease: 'easeInOut',
      times: [0, 0.5],
      repeat: Infinity,
      repeatDelay: 0,
      delay: 0,
    },
  },
];
