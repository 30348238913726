import React, { useState } from 'react';
import { Button, Container, PageSection } from '@/components/_root';

import { IGatsbyImageData } from 'gatsby-plugin-image';
import { FlexWrapper, ImageSection } from './styled';
import SectionTitle from '../section-title';
import BookDownloadModal from '@/components/book-download-modal';
interface Props {
  title: { outlined: string; solid: string; isSolidFirst?: boolean };
  description: string;
  bookImageUrl: IGatsbyImageData | any;
  actionButton: { title: string; url?: string; onClick?: () => void };
  downloadLink?: string;
}

const BookOfFameSection = ({
  title,
  description,
  bookImageUrl,
  actionButton,
  downloadLink,
}: Props): JSX.Element => {
  const [showBookFormModal, setShowBookFormModal] = useState<boolean>(false);
  return (
    <>
      <div style={{ background: '#111' }}>
        <Container>
          <FlexWrapper>
            <div className="lg:w-7/12">
              <PageSection paddingAmount="small" mode="dark">
                <div className="flex flex-col">
                  <SectionTitle title={title} mode="dark" />
                </div>
                <p className="text-white lg:w-8/12 my-6">{description}</p>
                <Button
                  mode="dark"
                  shape="round"
                  size="medium"
                  className="block w-full md:inline-block md:w-auto px-14"
                  type="primary"
                  onClick={(): void => setShowBookFormModal(true)}
                >
                  {actionButton?.title ?? 'Download Now'}
                </Button>
              </PageSection>
            </div>
            <ImageSection bookImageUrl={bookImageUrl} />
          </FlexWrapper>
        </Container>
      </div>
      <BookDownloadModal
        isOpen={showBookFormModal}
        onClose={(): void => setShowBookFormModal(false)}
        downloadLink={downloadLink}
      />
    </>
  );
};

export default BookOfFameSection;
