import React, { ReactNode } from 'react';
import { IconLeftDoubleQuote } from '@/components/_icons';
import { StaticImage } from 'gatsby-plugin-image';

interface Props {
  children?: ReactNode;
}

interface OverlayProps extends Props {
  isGradient?: boolean;
}

interface FeedbackProps extends OverlayProps {
  iconSize: number;
  item: {
    heading: string;
    description: string;
    brandname: string;
  };
}

export const OverlayContainer = ({ children, isGradient }: OverlayProps): JSX.Element => (
  <>
    {isGradient ? (
      <div className="bg-no-repeat w-full bg-center relative">
        <StaticImage
          src="../../../assets/img/blog-hub-banner.png"
          alt=""
          className="w-full z-0 h-full"
          style={{ position: 'absolute' }}
          placeholder="blurred"
        />
        <div className="relative z-10 pt-10">{children}</div>
      </div>
    ) : (
      <div>{children}</div>
    )}
  </>
);

export const FeedbackItem = ({ item, iconSize, isGradient }: FeedbackProps): JSX.Element => (
  <div className="w-5/6 md:w-full pt-8 pr-4 2xl:pr-8 flex space-x-2 2xl:space-x-4">
    <div className="-mt-1 md:-mt-0">
      <IconLeftDoubleQuote
        size={iconSize}
        stopColor={{
          color1: isGradient ? '#FFFF' : '#FF00E5',
          color2: isGradient ? '#FFFF' : '#F19A53',
        }}
      />
    </div>
    <div className={isGradient ? 'text-white' : 'text-dominant'}>
      <div className="pb-4">
        <h3 className={`inline ${isGradient ? 'text-white' : 'text-dominant'}`}>{item.heading}</h3>
      </div>
      <p className="text-sm pb-8 hidden md:block">{item.description}</p>
      <p
        className={`font-bold text-sm ${isGradient ? 'text-white' : 'text-brand-pink'}`}
      >
        {item.brandname}
      </p>
    </div>
  </div>
);

export const HeaderWrapper = ({ children }: Props): JSX.Element => (
  <div className="pb-8 md:pb-16 lg:pb-20 w-full md:w-11/12">{children}</div>
);
