import HoverList from '@/components/hover-list';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const BlogWrapper = ({ children }: Props): JSX.Element => (
  <HoverList className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8 gap-y-12 transition duration-300 ease-in-out">
    {children}
  </HoverList>
);

export const HeadingWrapper = ({ children }: Props): JSX.Element => (
  <div className="pb-8 md:pb-20">{children}</div>
);
