import React, { useState } from 'react';
import Layout from '@/components/_layout';
import Seo from '@/components/_layout/seo';
import HeroSection from '@/components/hero-section';
import BrandSection from '@/components/brand-section';
import AwardsSection from '@/components/awards-section';
import BookOfFameSection from '@/components/book-of-fame-section';

import { getTitle } from '@/utils/helper/getTitle';
import Services from '@/components/services';
import ClientsFeedback from '@/components/clients-feedback';
import CaseStudies from '@/components/case-studies';
import Blog from '@/components/blog';
import formatCaseStudies from '@/utils/helper/format-case-studies';
import { graphql, PageProps } from 'gatsby';
import { setBlogFeaturedData } from '@/utils/helper/blog';

const IndexPage = ({ data, location }: PageProps<any>): JSX.Element => {
	const [showVideoBanner, setShowVideoBanner] = useState<boolean>(false);

	return (
		<Layout location={location} shouldHideNav={showVideoBanner}>
			<Seo
				title={
					data?.sanityHomePageMetadata?.home_page_metadata?.pageMetaName ||
					'Home'
				}
				description={
					data?.sanityHomePageMetadata?.home_page_metadata?.pageMetaDescription
				}
				pageMetadata={
					data?.sanityHomePageMetadata?.home_page_metadata?.metadata
				}
				canonical={data?.sanityHomePageMetadata?.home_page_metadata?.canonical}
				opengraphImage={
					data?.sanityHomePageMetadata?.home_page_metadata?.opengraphImage
						?.asset?.url
				}
			/>
			<HeroSection
				backgroundVideoUrl={data?.sanityHero?.background_image?.asset?.url}
				backgroundVideoWebmUrl={
					data?.sanityHero?.background_image_webm?.asset?.url
				}
				backgroundVideoOggUrl={
					data?.sanityHero?.background_image_ogg?.asset?.url
				}
				backgroundVideoHiresUrl={
					data?.sanityHero?.background_image_hires?.asset?.url
				}
				subTitle={{
					tag: data?.sanityHero?.sub_header?.tag,
					text: data?.sanityHero?.sub_header?.text,
				}}
				titleSubText={data?.sanityHero?.subtitle}
				title={getTitle(data?.sanityHero)}
				leftActionButton={{ title: 'SEE our work', url: '/work/' }}
				rightActionButton={{ title: 'play showreel' }}
				awardLogoUrls={data?.sanityHero?.award_image}
				reminder={{
					imageUrl: data?.sanityReminders?.reminderFrom?.photo,
					imageAlt: 'carrie rose',
					reminderText: data?.sanityReminders?.reminderText,
					reminderLink: data?.sanityReminders?.reminderLink,
					notifications: data?.sanityReminders?.notifications,
				}}
				showVideoBanner={showVideoBanner}
				setShowVideoBanner={setShowVideoBanner}
			/>
			<div className='pt-28 md:pt-14 lg:pt-0'>
				<BrandSection
					title={getTitle(data?.sanityBrands)}
					brandLogoUrls={data?.sanityBrands?.brands}
				/>
			</div>
			<CaseStudies
				caseStudies={formatCaseStudies(
					data?.sanityCaseStudies?.featured_case_studies
				)}
			/>
			<Services
				title={getTitle(data?.sanityServices)}
				description={data?.sanityServices?.description}
				services={data?.sanityServices?.services}
			/>
			<ClientsFeedback
				title={getTitle(data?.sanityClientsFeedback)}
				feedbacks={data?.sanityClientsFeedback?.clients_feedback}
			/>
			<AwardsSection
				title={getTitle(data?.sanityAwards)}
				awardLogos={data?.sanityAwards?.awards}
			/>
			<BookOfFameSection
				title={getTitle(data?.sanityBookOfFame)}
				description={data?.sanityBookOfFame?.description}
				bookImageUrl={data?.sanityBookOfFame?.book_image}
				// bookImageUrl={TIKTOK_BOOK}
				actionButton={data?.sanityBookOfFame?.download_button}
				downloadLink={data?.sanityBookOfFame?.downloadable?.file?.asset?.url}
			/>

			<div className='relative bg-white'>
				<Blog
					blogs={
						data?.sanityOurBlogs?.blogs ||
						setBlogFeaturedData(data?.allSanityBlog?.edges)
					}
					title={getTitle(data?.sanityOurBlogs)}
				/>
			</div>
		</Layout>
	);
};

export default IndexPage;

export const query = graphql`
	query {
		sanityHomePageMetadata {
			home_page_metadata {
				metadata {
					metaContent
					metaName
				}
				canonical
				pageMetaDescription
				pageMetaName
				opengraphImage {
					asset {
						url
					}
				}
			}
		}
		sanityReminders {
			id
			reminderText
			reminderLink
			reminderFrom {
				photo {
					asset {
						gatsbyImageData(height: 10)
					}
				}
			}
			notifications {
				title
				image {
					asset {
						gatsbyImageData(height: 48)
					}
				}
			}
		}
		sanityHero {
			background_image {
				asset {
					url
				}
			}
			background_image_webm {
				asset {
					url
				}
			}
			background_image_ogg {
				asset {
					url
				}
			}
			background_image_hires {
				asset {
					url
				}
			}
			title {
				title_outlined
				title_solid
				is_outline_first
			}
			subtitle
			award_image {
				asset {
					gatsbyImageData(width: 100, placeholder: BLURRED)
				}
			}
			sub_header {
				tag
				text
			}
		}
		sanityBrands {
			title {
				title_solid
				title_outlined
				is_outline_first
			}
			brands {
				title
				image {
					asset {
						gatsbyImageData(width: 500, placeholder: BLURRED)
						mimeType
					}
				}
			}
		}
		sanityCaseStudies {
			id
			featured_case_studies {
				award_count
				award_icon {
					asset {
						gatsbyImageData(placeholder: BLURRED, sizes: "40")
					}
				}
				our_works {
					id
					slug {
						current
					}
					title {
						title_solid
						title_outlined
						is_outline_first
					}
					excerpt
					case_study_title
					client
					category {
						title
					}
					_rawBody
					banner {
						asset {
							gatsbyImageData(
								width: 800
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					thumbnail {
						asset {
							gatsbyImageData(
								width: 360
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					featuredImage {
						asset {
							gatsbyImageData(
								width: 800
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
		}
		sanityServices {
			description
			title {
				is_outline_first
				title_outlined
				title_solid
			}
			services {
				title
				description
				link
			}
		}
		sanityClientsFeedback {
			clients_feedback {
				quote_secondary
				quote_primary
				client_name
			}
			title {
				title_outlined
				title_solid
				is_outline_first
			}
		}
		sanityAwards {
			id
			title {
				is_outline_first
				title_outlined
				title_solid
			}
			awards {
				image {
					asset {
						gatsbyImageData(width: 500, placeholder: BLURRED)
						mimeType
					}
				}
			}
		}
		sanityBookOfFame {
			book_image {
				asset {
					gatsbyImageData(width: 500, placeholder: BLURRED)
				}
			}
			description
			title {
				title_outlined
				title_solid
				is_outline_first
			}
			download_button {
				title
				url
			}
			downloadable {
				file {
					asset {
						title
						url
					}
				}
			}
		}
		allSanityBlog(limit: 3, sort: { fields: _createdAt, order: DESC }) {
			edges {
				node {
					id
					slug {
						current
					}
					title
					excerpt
					thumbnail {
						asset {
							gatsbyImageData(width: 500, placeholder: BLURRED)
						}
					}
				}
			}
		}
		sanityOurBlogs {
			title {
				title_solid
				title_outlined
				is_outline_first
			}
			blogs {
				love_count
				like_count
				clap_count
				blog {
					id
					slug {
						current
					}
					title
					excerpt
					thumbnail {
						asset {
							gatsbyImageData(width: 500, placeholder: BLURRED)
						}
					}
				}
			}
		}
	}
`;
