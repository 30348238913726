import React, { useState, useEffect, useMemo } from 'react';
import {
	Button,
	PageSection,
	Swiper,
	SwiperItem,
	Container,
	Image,
} from '@/components/_root';
import {
	CaseStudiesContent,
	CaseStudiesSection,
	CaseStudiesActions,
	CaseStudyListHeader,
	CaseStudiesItem,
} from './styled';
import { useStopwatch, useViewport } from '@/components/_hook';
import { ICaseStudy } from '@/utils/types';
import { getCarouselItemDimension } from '@/utils/carousel';
import VisibilitySensor from 'react-visibility-sensor';

const AUTOPLAY_DELAY = 15 * 1000; // in ms
const CAROUSEL_ITEM_MIN_HEIGHT = 200;
const CAROUSEL_ITEM_MAX_HEIGHT = 260;
const DEFAULT_SUMMARY_LENGTH = 50;

interface Props {
	caseStudies: ICaseStudy[];
}

const CaseStudies = ({ caseStudies }: Props): JSX.Element => {
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const [hoverIndex, setHoverIndex] = useState<number>(-1);
	const { container } = useViewport();

	const { elapsedTime, startTimer, pauseTimer, resetTimer } = useStopwatch();

	const {
		brandName,
		title,
		lgBannerLink,
		summary,
		awardCount,
		caseStudyUrl,
		awardIcon,
	} = caseStudies[activeIndex];

	useEffect(() => {
		if (elapsedTime === AUTOPLAY_DELAY + 100) {
			resetTimer();
			setActiveIndex(
				activeIndex < caseStudies.length - 1 ? activeIndex + 1 : 0
			);
		}
	}, [activeIndex, resetTimer, elapsedTime, caseStudies.length]);

	const onUpdateActiveIndex = (index: number): void => {
		if (activeIndex !== index) {
			setActiveIndex(index);
			resetTimer();
		}
	};

	const itemHeight = useMemo(
		() =>
			getCarouselItemDimension(
				CAROUSEL_ITEM_MIN_HEIGHT,
				CAROUSEL_ITEM_MAX_HEIGHT,
				container
			),
		[container]
	);

	const summaryArr = summary?.split(' ') || [];
	let summaryTrimmed = summary;
	if (summaryArr.length > DEFAULT_SUMMARY_LENGTH) {
		summaryTrimmed = `${summaryArr
			.slice(0, DEFAULT_SUMMARY_LENGTH)
			.join(' ')}...`;
	}

	return (
		<VisibilitySensor
			onChange={(isVisible: boolean): void => {
				if (isVisible) startTimer();
				else pauseTimer();
			}}
			partialVisibility
		>
			<CaseStudiesSection backgroundBanner={lgBannerLink}>
				<PageSection className={`pb-0 lg:pt-40 lg:pb-0 `}>
					<Container>
						<CaseStudiesContent>
							<div className={`flex flex-col`}>
								<CaseStudiesSection.SectionLogo logo={brandName} />
								<div className={`sm:h-64`}>
									<h3 className={`pb-4`}>{title}</h3>
									<p className={`pb-8`}>{summaryTrimmed}</p>
									<CaseStudiesActions>
										<Button href={caseStudyUrl} className='order-4 py-4 w-56'>
											View Case Study
										</Button>
										{awardCount && awardCount > 0 && (
											<span className='order-3 sm:order-5'>
												<CaseStudiesActions.Awards
													count={awardCount}
													awardIcon={awardIcon}
												/>
											</span>
										)}
									</CaseStudiesActions>
								</div>
							</div>
						</CaseStudiesContent>
						<CaseStudyListHeader>
							<h4>More case studies</h4>
						</CaseStudyListHeader>
					</Container>
				</PageSection>
				<div className={`pb-10`} style={{ marginTop: -36 }}>
					<Swiper
						showBleeding
						haveOffset
						showNavigation
						activeItemIndex={activeIndex}
						onUpdateActiveIndex={setActiveIndex}
						spaceBetween={{ sm: 16, md: 20, lg: 24, xl: 28 }}
						isLoop={false}
						preloadImages={false}
						lazy={true}
					>
						{caseStudies.map((caseStudy: ICaseStudy, index: number) => (
							<SwiperItem key={index} style={{ maxWidth: 360 }}>
								<CaseStudiesItem
									isHover={hoverIndex === index}
									onMouseOver={(): void => {
										if (activeIndex === index) pauseTimer();
										setHoverIndex(index);
									}}
									onMouseLeave={(): void => {
										startTimer();
										setHoverIndex(-1);
									}}
									onClick={(): void => {
										onUpdateActiveIndex(index);
									}}
									height={itemHeight}
								>
									<Image
										src={caseStudy.thumbLink}
										alt={title}
										className={`flex-1 rounded object-cover w-full h-full`}
									/>
									<CaseStudiesItem.Title
										title={caseStudy.brandName}
										isHover={hoverIndex === index}
									/>
									{activeIndex === index && (
										<CaseStudiesSection.ProgressBar
											elapsedTime={elapsedTime}
											totalTime={AUTOPLAY_DELAY}
										/>
									)}
								</CaseStudiesItem>
							</SwiperItem>
						))}
					</Swiper>
					<noscript>
						<div className='flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-16 mx-auto container px-8 md:px-0'>
							{caseStudies
								.slice(0, 4)
								.map((caseStudy: ICaseStudy, index: number) => {
									return (
										<div className='relative'>
											<a href={caseStudy.caseStudyUrl}>
												<CaseStudiesItem height={itemHeight}>
													<img
														src={
															caseStudy?.thumbLink?.asset?.gatsbyImageData
																?.images?.fallback?.src
														}
														alt={title}
														className={`flex-1 rounded object-cover w-full h-full`}
													/>
													<CaseStudiesItem.Title
														title={caseStudy.brandName}
														isHover={hoverIndex === index}
													/>
												</CaseStudiesItem>
											</a>
										</div>
									);
								})}
						</div>
					</noscript>
				</div>
			</CaseStudiesSection>
		</VisibilitySensor>
	);
};

export default CaseStudies;
