import { useState, useEffect } from 'react';
import { getWindow } from 'ssr-window';

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1200,
};

interface Dimension {
  container: 'sm' | 'md' | 'lg' | 'xl';
  width: number;
  height: number;
}

const getContainer = (width: number): 'sm' | 'md' | 'lg' | 'xl' => {
  if (width <= breakpoints.sm) return 'sm';
  if (width <= breakpoints.md) return 'md';
  if (width <= breakpoints.lg) return 'lg';
  return 'xl';
};

const getWindowDimensions = (): Dimension | any => {
  const { innerWidth: width, innerHeight: height } = getWindow();
  return {
    width,
    height,
  };
};

const useWindowDimensions = (): Dimension => {
  const { width, height } = getWindowDimensions();
  const [container, setContainer] = useState(getContainer(width));
  const [windowDimensions, setWindowDimensions] = useState({ width, height });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize(): void {
      const { width: updatedWidth, height: updatedHeight } = getWindowDimensions();
      setWindowDimensions({ width: updatedWidth, height: updatedHeight });
      setContainer(getContainer(updatedWidth));
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  return { container, ...windowDimensions };
};

export default useWindowDimensions;
