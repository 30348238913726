import React, { useEffect } from 'react';
import { Container, Image } from '@/components/_root';
import Button from '@/components/_root/button';
import { PlayRoundIcon, CloseIcon } from '@/components/_icons';
import {
	ActionButtonContainer,
	AwardLogoContainer,
	RoundedOutlinedNumber,
	ContentWrapper,
	OverlayContainer,
	FloatingContent,
} from './styled';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import SectionTitle from '@/components/section-title';
import HeroPoster from '../../assets/img/hero-poster.jpg';

interface Props {
	backgroundVideoUrl: string;
	backgroundVideoWebmUrl: string;
	backgroundVideoOggUrl: string;
	backgroundVideoHiresUrl: string;
	subTitle: { tag: string; text: string };
	title: { outlined: string; solid: string; isSolidFirst?: boolean };
	leftActionButton: { title: string; url?: string; onClick?: () => void };
	rightActionButton: { title: string; url?: string; onClick?: () => void };
	awardLogoUrls: any[];
	reminder?: {
		imageUrl?: string | IGatsbyImageData;
		imageAlt?: string;
		notifications?: any[] | string[];
		reminderText?: string;
		reminderLink?: string;
	};
	showVideoBanner: boolean;
	titleSubText?: string;
	setShowVideoBanner: (value: boolean) => void;
}

const HeroSection = ({
	backgroundVideoUrl,
	backgroundVideoWebmUrl,
	backgroundVideoOggUrl,
	backgroundVideoHiresUrl,
	subTitle,
	title,
	leftActionButton,
	rightActionButton,
	awardLogoUrls,
	reminder,
	showVideoBanner,
	setShowVideoBanner,
	titleSubText,
}: Props): JSX.Element => {
	useEffect(() => {
		document.getElementById('rise-at-seven')?.play();
	}, []);

	if (showVideoBanner) {
		return (
			<div className={'relative'}>
				<video
					autoPlay
					loop
					controls
					muted={false}
					id='rise-at-seven'
					className={`w-full h-screen object-cover lazy`}
					preload='none'
					poster={HeroPoster}
				>
					<source src={backgroundVideoHiresUrl} type='video/mp4' />
				</video>
				<button
					onClick={(): void => setShowVideoBanner(false)}
					className={`absolute top-8 right-8 w-12 h-12 p-0 flex items-center justify-center rounded-lg hover:bg-white hover:bg-opacity-25 transition-all ease-in-out duration-300`}
				>
					<CloseIcon size={48} />
				</button>
			</div>
		);
	}

	return (
		<OverlayContainer>
			<video
				muted
				loop
				id='rise-at-seven'
				className={`absolute opacity-25 top-0 w-full h-full object-cover lazy`}
				preload='none'
				poster={HeroPoster}
			>
				<source src={backgroundVideoUrl} type='video/mp4' />
				<source src={backgroundVideoWebmUrl} type='video/webm' />
			</video>
			<noscript>
				<img
					src={HeroPoster}
					alt='Hero Background Image'
					className={`absolute opacity-25 top-0 w-full h-full object-cover`}
				/>
			</noscript>
			{!showVideoBanner && (
				<Container>
					<ContentWrapper>
						<div className='flex flex-row my-16 items-center'>
							<RoundedOutlinedNumber>{subTitle.tag}</RoundedOutlinedNumber>
							<h5
								className='text-white max-w-xs'
								style={{ lineHeight: '24px' }}
							>
								{subTitle.text}
							</h5>
						</div>
						<div className='flex flex-col'>
							<SectionTitle title={title} size='h1' mode='dark' />
						</div>
						<p className={'font-semibold text-white py-4'}>{titleSubText}</p>
						<ActionButtonContainer>
							<Button
								shape='round'
								className='w-1/2 md:w-auto flex items-center'
								onClick={leftActionButton?.onClick}
								href={leftActionButton?.url}
							>
								{leftActionButton.title}
							</Button>
							<Button
								shape='round'
								type='secondary'
								size='small'
								mode='dark'
								className={`group w-1/2 md:w-auto ${
									rightActionButton.url && 'flex items-center gap-2'
								}`}
								onClick={(): void => {
									setShowVideoBanner(true);
									//loadVideo()
								}}
								href={rightActionButton?.url}
							>
								<span>{rightActionButton.title}</span>
								<PlayRoundIcon />
							</Button>
						</ActionButtonContainer>
						<AwardLogoContainer>
							{awardLogoUrls.map((awardLogoUrl, index) => (
								<Image src={awardLogoUrl} key={index} alt='' />
							))}
						</AwardLogoContainer>
						{reminder && <FloatingContent reminder={reminder} />}
					</ContentWrapper>
				</Container>
			)}
		</OverlayContainer>
	);
};

export default HeroSection;
