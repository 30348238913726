import React, { ReactNode } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

interface Props {
  children: ReactNode;
}

export const HeaderTextContainer = ({ children }: Props): JSX.Element => {
  return <div className="text-center mb-16 flex flex-col">{children} </div>;
};

export const OverlayContainer = ({ children }: Props): JSX.Element => {
  return (
    <div className="bg-no-repeat w-full bg-center relative">
      <StaticImage
        src="../../../assets/img/award-overlay.png"
        alt=""
        className="w-full z-0 h-full"
        style={{ position: 'absolute' }}
        placeholder="blurred"
      />
      <div className="relative z-10 pt-10">{children}</div>
    </div>
  );
};
