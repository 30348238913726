import React, { ReactNode } from 'react';
import { Card, Container, PageSection, SwiperItem } from '@/components/_root';
import { HeadingWrapper } from './styled';

import * as styles from './style.module.css';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import CarouselOrGrid from '@/components/carousel-or-grid';
import SectionTitle from '../section-title';
import { motion } from 'framer-motion';
import useWindowDimensions from '@/utils/hooks';

interface Props {
	title: { solid?: string; outlined?: string; isOutlinedFirst?: boolean };

	blogs: {
		love_count: string;
		like_count: string;
		clap_count: string;
		blog: {
			id: string;
			slug: { current: string };
			title?: string;
			thumbnail: IGatsbyImageData | string;
			excerpt: string;
		};
		imageAlt: string;

		description: string;
		user: {
			name: string;
			avatar: string;
		};
	}[];
}

const FadeInWhenVisible = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element => {
	const { container } = useWindowDimensions();
	if (container === 'sm' || container === 'md') {
		return (
			<motion.div
				initial='hidden'
				whileInView='visible'
				exit='hidden'
				viewport={{ once: false, amount: 0.7 }}
				transition={{ duration: 0.3 }}
				variants={{
					visible: { opacity: 1 },
					hidden: { opacity: 0.2 },
				}}
			>
				{children}
			</motion.div>
		);
	}
	return <>{children}</>;
};

const Blog = ({ title, blogs }: Props): JSX.Element => {
	return (
		<PageSection>
			<Container>
				<HeadingWrapper>
					<SectionTitle title={title} />
				</HeadingWrapper>
			</Container>
			<CarouselOrGrid withHoverList>
				{blogs?.map((blog, index) => (
					<SwiperItem key={index} className={`${styles.marginItem}`}>
						<FadeInWhenVisible>
							<Card.Blog
								slug={blog?.blog?.slug}
								id={blog?.blog?.id}
								thumbnail={blog?.blog?.thumbnail}
								bannerAlt={blog?.imageAlt}
								avatarLink={blog?.user?.avatar}
								avatarAlt={blog?.user?.name}
								description={blog?.blog?.excerpt}
								cardTitle={blog?.blog?.title}
								reactionStats={{
									like: blog?.like_count,
									love: blog?.love_count,
									celebrate: blog?.clap_count,
								}}
								size='large'
							/>
						</FadeInWhenVisible>
					</SwiperItem>
				))}
			</CarouselOrGrid>
			<noscript>
				<div className='flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 sm:gap-16 md:gap-20 lg:gap-24 max-w-6xl mx-auto'>
					{blogs?.slice(0, 4).map((blog, index: number) => {
						return (
							<Card.Blog
								slug={blog?.blog?.slug}
								id={blog?.blog?.id}
								thumbnail={blog?.blog?.thumbnail}
								bannerAlt={blog?.imageAlt}
								avatarLink={blog?.user?.avatar}
								avatarAlt={blog?.user?.name}
								description={blog?.blog?.excerpt}
								cardTitle={blog?.blog?.title}
								reactionStats={{
									like: blog?.like_count,
									love: blog?.love_count,
									celebrate: blog?.clap_count,
								}}
								size='large'
							/>
						);
					})}
				</div>
			</noscript>
		</PageSection>
	);
};

export default Blog;
