import React, { ReactNode } from 'react';
import { IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';
import { Image } from '@/components/_root';
import { Link } from 'gatsby';
import { heroReactionsData } from '@/utils/dummy-data/hero-reactions-data';
import { motion } from 'framer-motion';

interface Props {
  children: ReactNode;
}

interface FloatingContentProps {
  reminder: {
    imageUrl?: string | IGatsbyImageData;
    imageAlt?: string;
    notifications?: any[] | string[];
    reminderText?: string;
    reminderLink?: string;
  };
}

interface Notification {
  notifications?: any[] | string[];
}

export const AwardLogoContainer = ({ children }: Props): JSX.Element => (
  <div className="inline-flex gap-9 items-center">{children}</div>
);

export const ActionButtonContainer = ({ children }: Props): JSX.Element => (
  <div className="flex gap-5 mt-4 mb-16">{children}</div>
);

export const RoundedOutlinedNumber = ({ children }: Props): JSX.Element => (
  <h5 className="text-white rounded-full h-9 w-9 lg:h-14 lg:w-14 border-2 flex items-center justify-center lg:text-md mr-3 flex-shrink-0 text-sm">
    {children}
  </h5>
);

export const ContentWrapper = ({ children }: Props): JSX.Element => (
  <div className="pt-24 relative z-40">{children}</div>
);

export const OverlayContainer = ({ children }: Props): JSX.Element => {
  return (
    <div className="bg-no-repeat w-full md:max-h-full bg-center relative">
      <StaticImage
        src="../../../assets/img/hero-overlay-1.png"
        alt=""
        className="w-full z-0 h-full"
        style={{ position: 'absolute' }}
        placeholder="blurred"
      />
      <div className="relative z-10 pb-28">{children}</div>
    </div>
  );
};

export const FloatingContent = ({
  reminder: { imageUrl, imageAlt, reminderText, reminderLink, notifications },
}: FloatingContentProps): JSX.Element => (
  <>
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ delay: 1 }}
      viewport={{ once: false }}
    >
      <FloatingContent.Notification notifications={notifications} />
    </motion.div>
    <motion.div
      className="w-full md:w-96 bg-white shadow-lg  absolute z-30 -bottom-44 md:-bottom-42 md:-right-0 left-auto"
      style={{ borderRadius: '40px' }}
      initial={{ transform: `scale(0.5)`, opacity: 0 }}
      whileInView={{ transform: `scale(1)`, opacity: 1 }}
      viewport={{ once: false }}
    >
      <div className="flex flex-col justify-center items-center">
        <div className="py-3 flex flex-col gap-4 border-b-2 border-gray-200 w-full">
          <div className="w-11/12 mx-auto flex gap-2 justify-center items-center">
            <Image
              src={imageUrl}
              alt={imageAlt}
              className="rounded-full h-8 w-8 object-cover inline"
            />
            <p className="font-semibold text-sm">Reminder:</p>
          </div>
          <p className="w-9/12 mx-auto text-center text-sm">{reminderText}</p>
        </div>
        {reminderLink && (
          <Link to={reminderLink}>
            <p
              className="py-2 font-dm-sans normal-case"
              style={{
                background: `-webkit-linear-gradient(left,  #CB00FF, #FD1F87)`,
                WebkitTextFillColor: `transparent`,
                WebkitBackgroundClip: 'text',
              }}
            >
              Read now
            </p>
          </Link>
        )}
      </div>
    </motion.div>
  </>
);

const notificationsOpacity = [`opacity-100`, `opacity-75`, `opacity-50`, `opacity-25`];

FloatingContent.Notification = ({ notifications }: Notification): JSX.Element => {
  return (
    <>
      <div className="w-96 hidden lg:flex flex-col gap-4 absolute z-20 -bottom-2 -right-0 left-auto">
        {heroReactionsData.map((icon, index) => (
          <motion.div
            key={index}
            className={`absolute ${icon.className} ${icon.opacity}`}
            animate={icon.animate}
            transition={icon.transition}
          >
            {icon.icon}
          </motion.div>
        ))}
        {notifications?.map((notification, index) => (
          <div
            key={index}
            className={`flex items-center gap-4 text-white ${notificationsOpacity[index]}`}
          >
            {notification.image ? (
              <Image src={notification.image} className="w-12 h-12 rounded-full object-cover" />
            ) : (
              <StaticImage
                src="../../../assets/img/stock-icon.png"
                alt=""
                className="w-12 h-12 rounded-full object-cover"
              />
            )}
            <h4 className="w-11/12">“{notification.title}”</h4>
          </div>
        ))}
      </div>
    </>
  );
};
